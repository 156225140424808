<template>
    <div>
      <!-- Botón flotante en la parte inferior izquierda -->
      <button
        class="floating-button btn btn-primary"
        @click="openModal"
      >
        <span class="icon">📅</span>
        <span class="text">Horario Escolar</span>
      </button>
  
      <!-- Modal -->
      <div 
        class="modal fade" 
        id="scheduleModal" 
        tabindex="-1" 
        aria-labelledby="scheduleModalLabel" 
        aria-hidden="true"
        ref="modalElement"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-primary text-white">
              <h5 class="modal-title mb-0" id="scheduleModalLabel">🕒 HORARIO ESCOLAR</h5>
              <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
            </div>
            <div class="modal-body">
              <table class="table table-bordered text-center">
                <thead class="table-primary">
                  <tr>
                    <th>Nivel</th>
                    <th>Entrada</th>
                    <th>Salida</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(horario, index) in horarios" :key="index">
                    <td>{{ horario.nivel }}</td>
                    <td>{{ horario.entrada }}</td>
                    <td>{{ horario.salida }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { Modal } from 'bootstrap';
  
  export default {
    setup() {
      const modalElement = ref(null);
      let modalInstance = null;
  
      const horarios = ref([
        { nivel: "Preescolar", entrada: "7:00 A.M.", salida: "12:00 .M." },
        { nivel: "Primaria", entrada: "6:00 A.M.", salida: "12:30 P.M." },
        { nivel: "Secundaria", entrada: "6:00 A.M.", salida: "1:30 P.M." },
        { nivel: "Media", entrada: "6:00 A.M.", salida: "1:30 P.M." }
      ]);
  
      const openModal = () => {
        if (modalInstance) modalInstance.show();
      };
  
      const closeModal = () => {
        if (modalInstance) modalInstance.hide();
      };
  
      onMounted(() => {
        if (modalElement.value) {
          modalInstance = new Modal(modalElement.value);
        }
      });
  
      onUnmounted(() => {
        if (modalInstance) {
          modalInstance.dispose();
        }
      });
  
      return { modalElement, openModal, closeModal, horarios };
    }
  };
  </script>
  
  <style>
  /* Botón flotante en la parte inferior izquierda */
  .floating-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    border-radius: 6000px;
    width: auto;
    height: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1050;
    gap: 5px; /* Espacio entre icono y texto */
    padding: 8px 12px;
  }
  
  /* Ajustes del icono y texto dentro del botón */
  .floating-button .icon {
    font-size: 18px;
  }
  
  .floating-button .text {
    font-size: 14px;
    white-space: nowrap;
  }
  
  /* Mejoras en el modal */
  .modal-header {
    border-bottom: none;
    padding: 15px;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    flex-grow: 1;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  /* Mejoras en la tabla */
  .table {
    font-size: 16px;
  }
  </style>
  